import _ from 'lodash';
import React, { useCallback, useEffect,  useMemo,  useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CheckmarkIcon, Col, XIcon, IconButton, LabeledInput, Row, } from '@commonsku/styles';
import { toast } from 'react-toastify';
import { rebuildTooltip } from '../../helpers';
import {
    createCompanyEmailDomains,
    deleteCompanyEmailDomain,
    updateCompanyEmailDomains,
} from '../../../redux/company_email_domains';
import {
    getCompanyId,
    getCompanyEmailDomains,
    getCompany,
    getEmailDomainId as getDomainId,
} from '../selectors';

const CompanyEmailDomains = React.memo((props) => {
    const {
        gotoNextStep,
        gotoPrevStep,
    } = props;
    const dispatch = useDispatch();
    const companyId = useSelector(getCompanyId);
    const companyEmailDomainsData = useSelector(getCompanyEmailDomains);
    const company = useSelector(getCompany);
    const companyType = useMemo(
        () => _.get(company, ['company_type'], 'DISTRIBUTOR'),
        [company]
    );

    const [editingIndex, setEditingIndex] = useState(-1);
    const [companyEmailDomains, setCompanyEmailDomains] = useState([]);

    const handleUpdateEmailDomain = useCallback(
        (index, data) => {
            setEditingIndex(index);
            setCompanyEmailDomains(
                s => ([
                    ...s.slice(0, index),
                    {...s[index], ...data},
                    ...s.slice(index+1),
                ])
            );
        },
        []
    );

    const onAddEmailDomain = useCallback(
        (email_domain) => {
            setCompanyEmailDomains(s => {
                setEditingIndex(s.length);
                return [
                    ...s,
                    { to_be_added: true, email_domain: email_domain, company_id: companyId }
                ];
            });
        },
        [companyId]
    );

    const getEmailDomainId = useCallback((domain) => {
        return getDomainId(companyType, domain);
    }, [companyType]);

    const onCreate = (domain) => {
        if (!domain.email_domain) {
            toast.error(<>
                <span style={{display: 'block'}}>Unable to create email domain</span>
                <span>Invalid domain</span>
            </>);
            return;
        }

        Promise.resolve(
            dispatch(createCompanyEmailDomains({
                email_domain: domain.email_domain,
                company_type: companyType,
                company_id: companyId,
            }))
        ).then((data) => {
            const err = _.get(data, ['error'], null);
            if (!err) { return; }
            toast.error(err);
        });
    };

    const onUpdate = (domain) => {
        const id = getEmailDomainId(domain);
        if (!id) { return; }

        Promise.resolve(
            dispatch(
                updateCompanyEmailDomains(
                    id,
                    companyType,
                    domain.email_domain
                )
            )
        ).then((data) => {
            const err = _.get(data, ['error'], null);
            if (!err) { return; }
            toast.error(err);
        });
    };

    const onDelete = (domain) => {
        Promise.resolve(
            dispatch(
                deleteCompanyEmailDomain(getEmailDomainId(domain), companyType)
            )
        ).then((data) => {
            const err = _.get(data, ['error'], null);
            if (!err) { return; }
            toast.error(err);
        });
    };

    const onContinue = () => {
        for (const emailDomain of companyEmailDomains) {
            const id = getEmailDomainId(emailDomain);

            if ((id && !emailDomain.editing)
                || (!id && !emailDomain.to_be_added)
                || !emailDomain.email_domain
            ) { continue; }

            if (emailDomain.to_be_added) {
                onCreate(emailDomain);
            } else if (id && emailDomain.editing) {
                onUpdate(emailDomain);
            }
        }

        gotoNextStep();
    };

    useEffect(() => {
        if (companyEmailDomainsData) {
            setCompanyEmailDomains(companyEmailDomainsData);
            setEditingIndex(-1);
        }
        rebuildTooltip();
    }, [companyEmailDomainsData]);

    return (
        <Row>
            {companyEmailDomains.map((domain, i) => (
                <Col padded xs md={10} mdOffset={1} mdOffsetRight={1} key={'email_domain-' + i}>
                    <span style={{ display: 'inline-block', paddingRight: 10, }}>
                        <IconButton
                            Icon={XIcon}
                            variant='primary-light'
                            size='tiny'
                            onClick={() => onDelete(domain)}
                            disabled={editingIndex !== i && editingIndex !== -1}
                        />
                    </span>
                    <span style={{ display: 'inline-block', width: '80%', }}>
                        <LabeledInput
                            label={'Email domain'}
                            name={'email_domain-' + i}
                            value={domain.email_domain}
                            onChange={e => {
                                handleUpdateEmailDomain(i, {
                                    email_domain: e.target.value,
                                    editing: true,
                                });
                            }}
                            disabled={editingIndex !== i && editingIndex !== -1}
                        />
                    </span>
                    {domain.to_be_added || (domain.editing && domain.email_domain) ? <span style={{ display: 'inline-block', paddingLeft: 10, }}>
                        <IconButton
                            Icon={CheckmarkIcon}
                            variant='primary-light'
                            size='tiny'
                            onClick={() => {
                                if (getEmailDomainId(domain) && domain.editing) {
                                    onUpdate(domain);
                                } else {
                                    onCreate(domain);
                                }
                            }}
                            disabled={editingIndex !== i && editingIndex !== -1}
                        />
                    </span> : null}
                </Col>
            ))}
            <Col xs md={8} mdOffset={2} end={1}>
                <Button size='medium' variant='primary' onClick={() => onAddEmailDomain('')}>Add Email Domain</Button>
            </Col>
            <Col xs center style={{paddingTop: 40, paddingBottom: 10}}>
                <Button variant='secondary' style={{ marginRight: 10 }} onClick={gotoPrevStep}>Back</Button>
                <Button variant='primary' onClick={onContinue}>Continue</Button>
            </Col>
        </Row>
    );
});

export default CompanyEmailDomains;
