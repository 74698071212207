import _ from 'lodash';
import React, { useCallback, useEffect,  useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ClipboardIcon, Col, fontFamilies, IconButton, LabeledInput, Link, Row, Text, } from '@commonsku/styles';
import { LabeledSelect, rebuildTooltip } from '../../helpers';
import {
    getCompany,
    getCompanyUsers,
    getCompanyEmailDomains,
} from '../selectors';
import { getCompanyExtraData, updateCompany } from '../../../redux/companies';
import CompanyProfile from './CompanyProfile';
import { createAddUser } from '../../../actions';
import { createCompanyEmailDomains } from '../../../redux/company_email_domains';
import { formatActivationLink, oauth } from '../../../utils';
import { currency_options } from '../../../constants';
import useFeatures from '../../../hooks/useFeatures';
import withReducers from '../../../store/withReducers';
import featuresReducer from "../../../redux/features";
import { paymentModelOptions } from '../../dashboard';
import Features from '../../../constants/Features';

const skipEmailDoamins = [
    'gmail',
    'hotmail',
    'outlook',
    'live',
    'yahoo',
];

const InitialSetup = React.memo((props) => {
    const {
        gotoNextStep,
        showActivation,
        setShowActivationLink,
        isSaving,
        setSaving,
        hasOnlyInactiveSocialUsers,
    } = props;
    const dispatch = useDispatch();

    const companyEmailDomainsData = useSelector(getCompanyEmailDomains);
    const companyData = useSelector(getCompany);
    const companyUsersData = useSelector(getCompanyUsers);

    const [companyUser, setCompanyUser] = useState({
        contact_first_name: '',
        contact_last_name: '',
        contact_email: '',
        login_name: '',
    });
    const [company, setCompany] = useState({
        company_id: '',
        company_type: '',
        company_name: '',
        tenant_domain_key: '',
        default_currency_id: '',
    });
    const [companyEmailDomain, setCompanyEmailDomain] = useState('');

    const [features] = useFeatures(company.company_type);
    const allowedFeatures = ['ESSENTIAL','ADVANCED'];
    const featureDropdownItems = features.filter((f) => allowedFeatures.includes(f.feature_name))
      .map((f) => {
        return {
          label: f.feature_description,
          value: f.feature_id
        }
      });

    const updateCompanyField = useCallback(
        (field, value) => setCompany(s => ({ ...s, [field]: value })),
        []
    );

    const checkEmail = useCallback((email) => {
        return oauth(
            'GET',
            'public-email-domain',
            { check: email }
        ).then(({ json }) => json);
    }, []);

    const addCommonskuUser = useCallback(async () => {
        try {
            return await oauth('POST', 'user', {
                commonsku_user: true,
                company_type: company.company_type,
                company_id: company.company_id,
            });
        } catch(err) {
            toast.error('Unable to create commonsku user');
        }
        return null;
    }, [company]);

    const addCompanyEmailDomain = useCallback(async (emailDomain) => {
        const data = await dispatch(createCompanyEmailDomains({
            email_domain: emailDomain,
            company_type: company.company_type,
            company_id: company.company_id,
        }));

        const err = _.get(data, ['error'], null);
        if (err) {
            toast.error(err);
            return;
        }

        await addCommonskuUser();
    }, [dispatch, company, addCommonskuUser]);

    const addCompanyUser = useCallback(async (userData) => {
        if (!userData.contact_email) {
            toast.error('invalid user email');
            return;
        }

        const action = await dispatch(createAddUser({
            contact_email: userData.contact_email,
            contact_first_name: userData.contact_first_name,
            contact_last_name: userData.contact_last_name,
            company_type: company.company_type,
            company_id: company.company_id,
            company_user: true,
        }));

        const err = _.get(action, ['payload', 'error'], null);
        if (err) {
            toast.error(err);
            return false;
        }

        setShowActivationLink(true);
        if (companyEmailDomain) {
            await addCompanyEmailDomain(companyEmailDomain);
        }
    }, [
        dispatch,
        company,
        setShowActivationLink,
        companyEmailDomain,
        addCompanyEmailDomain,
    ]);

    const onUpdateCompany = useCallback(async () => {
        if (!companyUser.contact_first_name) {
            toast.error('invalid user first name');
            return;
        }

        if (!companyUser.contact_email) {
            toast.error('invalid user email');
            return;
        }

        const emailCheck = await checkEmail(companyUser.contact_email);
        if (_.get(emailCheck, ['public'], false)) {
            toast.error('email cannot be of public domain');
            return;
        }

        if (company.company_domain_key) {
            const tenantDomainCheck = await checkEmail(`test@${company.company_domain_key}`);
            if (_.get(tenantDomainCheck, ['public'], false)) {
                toast.error('commonsku domain cannot be public');
                return;
            }
        }

        if (companyEmailDomain) {
            const emailDomainCheck = await checkEmail(`test@${companyEmailDomain}`);
            if (_.get(emailDomainCheck, ['public'], false)) {
                toast.error('email domain cannot be public');
                return;
            }
        }

        if (!company.feature_id || company.feature_id === Features.DISTRIBUTOR_SOCIAL_FEATURE_ID) {
            toast.error('you must choose a feature level');
            return;
        }

        const params = {
            default_currency_id: company.default_currency_id,
        };
        let companyType = company.company_type.toLowerCase();
        if (['DISTRIBUTOR', 'TENANT'].includes(company.company_type)) {
            companyType = 'tenant';
            params['tenant_domain_key'] = company.company_domain_key;
            params['feature_id'] = company.feature_id;
            params['payment_model'] = company.payment_model;
            params['tenant_name'] = company.company_name;
        } else {
            params['supplier_domain_key'] = company.company_domain_key;
            params['supplier_name'] = company.company_name;
        }

        const action = await dispatch(updateCompany(
            companyType,
            company.company_id,
            params
        ));

        if (!action) { return; }
        if (action.error) {
            toast.error(action.error);
            return;
        }

        await addCompanyUser(companyUser);
    }, [
        dispatch,
        company,
        companyUser,
        companyEmailDomain,
        checkEmail,
        addCompanyUser,
    ]);

    useEffect(() => {
        if (companyData) {
            const data = getCompanyExtraData(companyData);
            setCompany({
                ...data,
                ...companyData,
                public: +companyData.public === 1,
            });
        }
        rebuildTooltip();
    }, [companyData]);

    useEffect(() => {
        const value = _.get(
            _.first(companyEmailDomainsData),
            ['email_domain'],
            ''
        );
        if (value && !companyEmailDomain) {
            setCompanyEmailDomain(value);
        }
        rebuildTooltip();
    }, [companyEmailDomainsData, companyEmailDomain]);

    useEffect(() => {
        if (!isSaving) { return; }
        setSaving(false);
        if (showActivation) { return; }

        Promise.resolve(onUpdateCompany());
    }, [
        onUpdateCompany,
        showActivation,
        isSaving,
        setSaving
    ]);

    if (showActivation && companyUsersData.length > 0) {
        const user = companyUsersData[0];
        const activationLink = formatActivationLink(user.user_activation_key);
        return (
            <Row>
                <Col padded xs md={8} mdOffset={2} mdOffsetRight={2}>
                    <Text as="p" style={{ fontFamily: fontFamilies.demibold, fontWeight: 'bold', fontSize: 20, }}>Company Name</Text>
                    <Text style={{ fontFamily: fontFamilies.regular, fontSize: 16, }}>{company.company_name}</Text>
                </Col>
                <Col padded xs md={8} mdOffset={2} mdOffsetRight={2}>
                    <Text as="p" style={{ fontFamily: fontFamilies.demibold, fontWeight: 'bold', fontSize: 20, }}>User name</Text>
                    <Text pr={5} style={{ fontFamily: fontFamilies.regular, fontSize: 16, }}>{user.contact_first_name}</Text>
                    <Text style={{ fontFamily: fontFamilies.regular, fontSize: 16, }}>{user.contact_last_name}</Text>
                </Col>
                <Col padded xs md={8} mdOffset={2} mdOffsetRight={2}>
                    <Text as="p" style={{ fontFamily: fontFamilies.demibold, fontWeight: 'bold', fontSize: 20, }}>User email</Text>
                    <Text style={{ fontFamily: fontFamilies.regular, fontSize: 16, }}>{user.contact_email}</Text>
                </Col>
                <Col padded pb={0} xs md={8} mdOffset={2} mdOffsetRight={2}>
                    <Text as="p" style={{ fontFamily: fontFamilies.demibold, fontWeight: 'bold', fontSize: 20, }}>Activation link</Text>
                </Col>
                <Col padded xs md={6} mdOffset={2}>
                    <Link as={'a'} href={activationLink} target='_blank' style={{ fontFamily: fontFamilies.regular, fontSize: 16, }}>{activationLink}</Link>
                </Col>
                <Col padded xs md={2} style={{ alignSelf: 'end' }}>
                    <IconButton
                        variant='primary-light'
                        onClick={e => { navigator.clipboard.writeText(activationLink); }}
                        Icon={<ClipboardIcon />}
                    />
                </Col>
            </Row>
        );
    }

    if (companyUsersData.length !== 0) {
        return <CompanyProfile
            hasOnlyInactiveSocialUsers={hasOnlyInactiveSocialUsers}
            gotoNextStep={gotoNextStep}
            companyUsersData={companyUsersData}
            featureDropdownItems={featureDropdownItems}
            paymentModelOptions={paymentModelOptions}
        />;
    }

    return (
        <Row>
            <Col padded xs md={8} mdOffset={2} mdOffsetRight={2}>
                <Row>
                    <Col xs>
                        <LabeledInput
                            label='Company name'
                            name='company_name'
                            value={company.company_name}
                            onChange={e => updateCompanyField('company_name', e.target.value)}
                        />
                    </Col>
                    <Col xs>
                        <LabeledInput
                            label='Signup user: Email'
                            name='admin_email'
                            value={companyUser.contact_email}
                            onChange={e => {
                                const value = e.target.value
                                setCompanyUser(s => ({
                                    ...s, contact_email: value,
                                }))
                            }}
                            onBlur={e => {
                                if (!companyUser.contact_email) { return; }
                                checkEmail(companyUser.contact_email)
                                    .then(check => {
                                        if (check.total > 0) {
                                            toast.error('email already exists');
                                            return;
                                        }
                                        if (!check.valid) {
                                            toast.error('invalid email');
                                            return;
                                        }
                                        if (check.public) {
                                            toast.error('email cannot be of public domain');
                                            return;
                                        }

                                        const split = companyUser.contact_email.split('@');
                                        if (split.length < 2) { return; }
                                        const emailDomain = split[1].split('.')[0];
                                        if (!emailDomain) { return; }
                                        if (!skipEmailDoamins.includes(emailDomain.toLowerCase())) {
                                            if (!company.company_domain_key) {
                                                updateCompanyField('company_domain_key', emailDomain);
                                            }
                                            if (!companyEmailDomain) {
                                                setCompanyEmailDomain(split[1]);
                                            }
                                        }
                                    });
                            }}
                        />
                    </Col>
                    <Col xs>
                        <LabeledInput
                            label='Signup user: First name'
                            name='admin_first_name'
                            value={companyUser.contact_first_name}
                            onChange={e => {
                                const value = e.target.value
                                setCompanyUser(s => ({
                                    ...s, contact_first_name: value,
                                }))
                            }}
                        />
                    </Col>
                    <Col xs>
                        <LabeledInput
                            label='Signup user: Last name'
                            name='admin_last_name'
                            value={companyUser.contact_last_name}
                            onChange={e => {
                                const value = e.target.value
                                setCompanyUser(s => ({
                                    ...s, contact_last_name: value,
                                }))
                            }}
                        />
                    </Col>
                    <Col xs>
                        <LabeledInput
                            label='Email domain'
                            name='email_domain'
                            value={companyEmailDomain}
                            onChange={e => setCompanyEmailDomain(e.target.value)}
                        />
                    </Col>
                    <Col xs>
                        <LabeledInput
                            label='commonsku domain'
                            name='company_domain_key'
                            value={company.company_domain_key}
                            onChange={e => updateCompanyField('company_domain_key', e.target.value)}
                        />
                    </Col>
                    <Col xs>
                        <LabeledSelect
                            label='Default currency'
                            name='default_currency_id'
                            options={currency_options}
                            value={company.default_currency_id}
                            onChange={e => updateCompanyField('default_currency_id', e ? e.value : '')}
                            inPopup
                        />
                    </Col>
                    <Col xs>
                        <LabeledSelect
                            label='Selected package'
                            name='feature_id'
                            options={featureDropdownItems}
                            value={company.feature_id}
                            onChange={e => updateCompanyField('feature_id', e ? e.value : '')}
                            inPopup
                        />
                        <LabeledSelect
                            label='Payment model'
                            name='feature_id'
                            options={paymentModelOptions}
                            value={company.payment_model}
                            onChange={e => updateCompanyField('payment_model', e ? e.value : '')}
                            inPopup
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
});

export default withReducers(InitialSetup, {
    features: featuresReducer
});
