import React, { useCallback, useEffect,  useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ClipboardIcon, Col, IconButton, LabeledInput, Link, Row, Text, } from '@commonsku/styles';
import { toast } from 'react-toastify';
import { LabeledSelect, rebuildTooltip } from '../../helpers';
import {
    getCompany,
} from '../selectors';
import { currency_options } from '../../../constants';
import { getCompanyExtraData, updateCompany } from '../../../redux/companies';
import { formatActivationLink, oauth } from '../../../utils';

const CompanyProfile = React.memo((props) => {
    const {
        gotoNextStep,
        hasOnlyInactiveSocialUsers,
        companyUsersData,
        featureDropdownItems,
        paymentModelOptions,
    } = props;
    const dispatch = useDispatch();
    const companyData = useSelector(getCompany);
    const [reactivateUserId, setReactivateUserId] = useState();
    const [featureId, setFeatureId] = useState(companyData.feature_id);
    const [paymentModel, setPaymentModel] = useState(companyData.payment_model);
    const [reinviteLink, setReinviteLink] = useState();

    const [company, setCompany] = useState({
        company_id: '',
        company_type: '',
        company_name: '',
        public: false,
        default_currency_id: 'USD',
    });

    const updateCompanyField = useCallback(
        (field, value) => setCompany(s => ({ ...s, [field]: value })),
        []
    );

    useEffect(() => {
        if (companyData) {
            const data = getCompanyExtraData(companyData);
            setCompany({
                ...data,
                ...companyData,
                public: +companyData.public === 1,
            });
        }
        rebuildTooltip();
    }, [companyData]);

    const onContinue = () => {
        const params = {
            public: company.public ? 1 : 0,
            default_currency_id: company.default_currency_id,
        };
        let companyType = company.company_type.toLowerCase();
        if (['DISTRIBUTOR', 'TENANT'].includes(company.company_type)) {
            companyType = 'tenant';
            params['tenant_domain_key'] = company.company_domain_key;
            params['tenant_name'] = company.company_name;
        } else {
            params['supplier_domain_key'] = company.company_domain_key;
            params['supplier_name'] = company.company_name;
        }

        Promise.resolve(
            dispatch(updateCompany(
                companyType,
                company.company_id,
                params
            ))
        ).then((action) => {
            if (!action) { return; }
            if (action.error) {
                toast.error(action.error);
                return;
            }

            gotoNextStep();
        });
    };

    const reactivateUser = () => {
        if (!featureId || !paymentModel || !reactivateUserId) {
            toast.error('All fields required');
            return;
        }

        oauth(
            'POST',
            'user-invitations',
            { featureId, paymentModel, reactivateUserId }
        ).then(({ json }) => {
            const activationLink = formatActivationLink(json.user.user_activation_key);
            setReinviteLink(activationLink);
        });
    }

    const companyUserOptions = companyUsersData.filter((user) => !user.hidden).map((user) => ({
        label: `${user.contact_first_name} ${user.contact_last_name} (${user.login_name})`,
        value: user.user_id
    }));

    const content = reinviteLink ?
        <div>
            <p style={{marginBottom: 10, fontSize: 16}}>Activation link:</p>
            <Link as={'a'} href={reinviteLink} target='_blank' style={{fontSize: 16, display: 'inline-block', width: 500}}>{reinviteLink}</Link>
            <IconButton
                mt={5}
                variant='primary-light'
                onClick={e => { navigator.clipboard.writeText(reinviteLink); }}
                Icon={<ClipboardIcon />}
            />
        </div> : (
        hasOnlyInactiveSocialUsers ?
        <>
            <Text as="p" style={{fontSize: 16}} pb={30}>
                All the users in the company are inactive and on Social Feature level. Choose a user to re-activate the invitation link.
            </Text>
            <Col xs>
                <LabeledSelect
                    label='Reactivate user'
                    name='reactivate_user_id'
                    options={companyUserOptions}
                    value={reactivateUserId}
                    onChange={e => setReactivateUserId(e.value)}
                    inPopup
                />
                <LabeledSelect
                    label='Selected package'
                    name='feature_id'
                    options={featureDropdownItems}
                    value={featureId}
                    onChange={e => setFeatureId(e.value)}
                    inPopup
                />
                <LabeledSelect
                    label='Payment model'
                    name='feature_id'
                    options={paymentModelOptions}
                    value={paymentModel}
                    onChange={e => setPaymentModel(e.value)}
                    inPopup
                />
                <Button
                    onClick={() => { reactivateUser(); }}
                >Re-invite User</Button>
            </Col>
        </> : <>
            <Col padded xs md={8} mdOffset={2} mdOffsetRight={2}>
                <LabeledInput
                    label='Company name'
                    name='company_name'
                    value={company.company_name}
                    onChange={e => updateCompanyField('company_name', e.target.value)}
                />
            </Col>
            <Col padded xs md={8} mdOffset={2} mdOffsetRight={2}>
                <LabeledInput
                    label='commonsku domain'
                    name='company_domain_key'
                    value={company.company_domain_key}
                    onChange={e => updateCompanyField('company_domain_key', e.target.value)}
                />
            </Col>
            <Col padded xs md={8} mdOffset={2} mdOffsetRight={2}>
                <LabeledSelect
                    label='Default currency'
                    name='default_currency_id'
                    options={currency_options}
                    value={company.default_currency_id}
                    onChange={e => updateCompanyField('default_currency_id', e ? e.value : '')}
                    inPopup
                />
            </Col>
            <Col xs center style={{paddingTop: 40, paddingBottom: 10}}>
                <Button variant='primary' onClick={onContinue}>Continue</Button>
            </Col>
        </>);

    return (
        <Row>
            {content}
        </Row>
    );
});

export default CompanyProfile;
