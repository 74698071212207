export { default as Status } from './Status';

export const currencies = [
    'USD',
    'CAD',
    'AUD',
    'NZD',
    'SGD',
    'GBP',
    'BBD',
    'INR',
    'MXN',
];
export const currency_options = currencies.map(v => ({label: v, value: v}));
