import ReactDOM from 'react-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { ArrowIcon, Button, Col, colors, IconButton, Popup, PopupHeader, Text } from '@commonsku/styles';
import { ToastContainer } from 'react-toastify';
import '../../../scss/css/react-toastify.css';
import { document } from '../../global';
import { oauth } from '../../utils';
import withReducers from '../../store/withReducers';
import { getCompany, getCompanyUsers } from './selectors';
import companiesSlice from '../../redux/companies';
import companyEmailDomainsSlice from '../../redux/company_email_domains';
import CompanyEmailDomains from './FormSteps/CompanyEmailDomains';
import CompanyUsers from './FormSteps/CompanyUsers';
import InitialSetup from './FormSteps/InitialSetup';
import Features from '../../constants/Features';

const CompanySetupPopup = (props) => {
    const {
        onClose,
        features=[],
    } = props;

    const companyUsersData = useSelector(getCompanyUsers);
    const [step, setStep] = useState('profile');
    const [showUserActivationLink, setShowActivationLink] = useState(false);
    const [isSaving, setSaving] = useState(false);

    let hasOnlyInactiveSocialUsers = true;
    for (const user of companyUsersData) {
        if (!user.hidden && (user.active || user.feature_id !== Features.DISTRIBUTOR_SOCIAL_FEATURE_ID)) {
            hasOnlyInactiveSocialUsers = false;
            break;
        }
    };

    const gotoNextStep = useCallback((data) => {
        setStep(s => {
            if (s === 'profile') {
                return 'email_domains';
            } else if (s === 'email_domains') {
                return 'users';
            }
            return s;
        });
    }, []);

    const gotoPrevStep = useCallback(() => {
        setStep(s => {
            if (s === 'email_domains') {
                return 'profile';
            } else if (s === 'users') {
                return 'email_domains';
            }

            return s;
        });
    }, []);

    const canGoBack = useMemo(() => {
        return ['email_domains', 'users'].includes(step);
    }, [step]);

    const stepTitle = useMemo(() => {
        if (step === 'email_domains') {
            return 'Email Domains';
        } else if (step === 'users') {
            return 'Users';
        }

        if (showUserActivationLink || companyUsersData.length === 0) {
            return 'Company Invite';
        }

        return 'Profile';
    }, [step, showUserActivationLink, companyUsersData]);

    return <Popup
        className='popup company-setup-popup csku-styles'
        mdStyle="width: 800px;"
        style={{height: 'fit-content', minHeight: 200, padding: '0 0 50px 0', borderRadius: 5, border: 1, boxShadow: '0 5px 5px 0 rgb(0 0 0 / 20%)',}}
        onClose={onClose}
        header={<PopupHeader className="popup-header" xsStyle="flex-wrap: wrap-reverse;" smStyle="flex-wrap: wrap;"
            style={{
                background: colors.primary1['65'],
                height: 115,
                borderTopRightRadius: 5,
                borderTopLeftRadius: 5,
                padding: 10,
            }}
        >
            <Col xs sm={8} style={{alignSelf: 'center'}}>
                {canGoBack ? <IconButton
                    variant='primary'
                    Icon={<ArrowIcon direction='left' color='white' />}
                    style={{ background: 'transparent', color: '#fff', borderColor: "transparent" }}
                    onClick={gotoPrevStep}
                /> : null}
                <Text className="title" style={{ color: '#fff', fontSize: '2rem', paddingLeft: canGoBack ? 0 : 20, }}>{stepTitle}</Text>
            </Col>
            <Col end={1} xs sm={4} style={{alignSelf: 'center'}}>
                <Button mr={5} variant='text' onClick={onClose}>Close</Button>
                {companyUsersData.length === 0 ? <Button
                    variant='cta'
                    onClick={() => { setSaving(true); }}
                    disabled={showUserActivationLink}
                >Invite User</Button> : null}
            </Col>
        </PopupHeader>}
    >
        <ToastContainer />
        <div style={{ paddingTop: 20, padding: 20, }}>
            <RenderContent
                step={step}
                features={features}
                gotoNextStep={gotoNextStep}
                gotoPrevStep={gotoPrevStep}
                onClose={onClose}
                gotoUsersStep={() => setStep('users')}
                showUserActivationLink={showUserActivationLink}
                setShowActivationLink={setShowActivationLink}
                isSaving={isSaving}
                setSaving={setSaving}
                hasOnlyInactiveSocialUsers={hasOnlyInactiveSocialUsers}
            />
        </div>
        {ReactDOM.createPortal(
            <ReactTooltip
                id={"user-info"}
                className="user-info-tooltip"
                place="left"
                clickable={true}
                delayHide={500}
                backgroundColor='#fff'
                borderColor='#000'
                textColor='#000'
                effect='solid'
            />,
            document.body
        )}
    </Popup>;
};

const CompanySetup = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [features, setFeatures] = useState([]);

    useEffect(() => {
        oauth('GET', 'feature', {
            company_type: 'DISTRIBUTOR'
        }).then(({ json }) => {
            setFeatures(json.features);
        });
    }, []);

    return <>
        {showPopup ? <CompanySetupPopup
            onClose={() => setShowPopup(false)}
            features={features}
        /> : null}
        {ReactDOM.createPortal(
          <ToastContainer
            autoClose={3000}
            hideProgressBar={true}
          />, document.body
        )}
        <a href='#' onClick={() => setShowPopup(true)}>Setup Company</a>
    </>;
};

const RenderContent = React.memo((props) => {
    const {
        step,
        features,
        gotoNextStep,
        gotoPrevStep,
        onClose,
        gotoUsersStep,
        showUserActivationLink,
        setShowActivationLink,
        isSaving,
        setSaving,
        hasOnlyInactiveSocialUsers,
    } = props;

    const company = useSelector(getCompany);

    if (step === 'email_domains') {
        return <CompanyEmailDomains
            gotoNextStep={gotoNextStep}
            gotoPrevStep={gotoPrevStep}
        />;
    } else if (step === 'users') {
        return <CompanyUsers
            features={features.filter((f) => [company.feature_id, Features.DISTRIBUTOR_SOCIAL_FEATURE_ID].includes(f.feature_id))}
            gotoNextStep={onClose}
            gotoPrevStep={gotoPrevStep}
        />;
    }

    return <InitialSetup
        gotoNextStep={gotoNextStep}
        gotoUsersStep={gotoUsersStep}
        showActivation={showUserActivationLink}
        setShowActivationLink={setShowActivationLink}
        isSaving={isSaving}
        setSaving={setSaving}
        hasOnlyInactiveSocialUsers={hasOnlyInactiveSocialUsers}
    />;
});

export default withReducers(
    CompanySetup,
    {
        [companiesSlice.name]: companiesSlice.reducer,
        [companyEmailDomainsSlice.name]: companyEmailDomainsSlice.reducer,
    },
    true
);
