import { createSelector } from 'reselect';

export const getCompanyId = createSelector(
    s => s.display.company_id,
    (companyId) => companyId
);

export const getCompany = createSelector(
    [
        s => s.companies,
        s => s.display.company_id,
    ],
    (s, companyId) => s[companyId]
);

export const getCompanyEmailDomains = createSelector(
    [
        s => Object.values(s.company_email_domains || {}),
        s => s.display.company_id,
    ],
    (s, companyId) => s.filter(v => v.tenant_id === companyId || v.supplier_id === companyId)
);

export const getCompanyUsers = createSelector(
    [
        s => Object.values(s.entities.users || {}),
        s => s.display.company_id,
    ],
    (s, companyId) => s.filter(v => v.tenant_id === companyId || v.supplier_id === companyId)
);

export const getEmailDomainId = (companyType, domain) => {
    let id = null;
    if (companyType === 'DISTRIBUTOR') {
        id = domain.tenant_email_domain_id;
    } else if (companyType === 'SUPPLIER') {
        id = domain.supplier_email_domain_id;
    }

    return id;
};
