import _ from 'lodash';
import React, { useCallback, useEffect,  useMemo,  useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CheckmarkIcon, Col, colors, IconButton, InfoIcon, Input, LabeledCheckbox, Link, Row, Text, } from '@commonsku/styles';
import { toast } from 'react-toastify';
import { rebuildTooltip, Select } from '../../helpers';
import {
    getCompany,
    getCompanyId,
    getCompanyUsers,
} from '../selectors';
import { createAddUser, createUpdateUser } from '../../../actions';
import { formatActivationLink } from '../../../utils';

const CompanyUsers = React.memo((props) => {
    const {
        features,
        gotoNextStep,
        gotoPrevStep,
    } = props;

    const dispatch = useDispatch();
    const companyId = useSelector(getCompanyId);
    const company = useSelector(getCompany);
    const companyType = useMemo(
        () => _.get(company, ['company_type'], 'DISTRIBUTOR'),
        [company]
    );

    const [showOnlyActiveUsers, setShowOnlyActiveUsers] = useState(true);
    const _companyUsersData = useSelector(getCompanyUsers);
    const companyUsersData = useMemo(() => {
        if (showOnlyActiveUsers) {
            return _.filter(_companyUsersData, v => parseInt(v.active) === 1);
        }
        return _companyUsersData;
    }, [showOnlyActiveUsers, _companyUsersData]);

    const [editingIndex, setEditingIndex] = useState(-1);
    const [companyUsers, setCompanyUsers] = useState([]);
    const updateCompanyUserField = useCallback(
        (index, field, value, editing=true) => {
            setEditingIndex(index);
            setCompanyUsers(
                s => ([
                    ...s.slice(0, index),
                    {...s[index], [field]: value, editing: editing},
                    ...s.slice(index+1),
                ])
            );
        },
        []
    );

    const onCreate = (user) => {
        if (!user.login_name) {
            toast.error(<>
                <span style={{display: 'block'}}>Unable to create user</span>
                <span>Invalid email</span>
            </>);
            return;
        }
        Promise.resolve(
            dispatch(createAddUser({
                contact_email: user.login_name,
                contact_first_name: user.contact_first_name,
                contact_last_name: user.contact_last_name,
                company_type: companyType,
                company_id: companyId,
                company_user: true,
            }))
        ).then(action => {
            if (!action.payload) { return; }
            if (!action.payload.error) { return; }
            toast.error(action.payload.error);
        });
    };

    const onUpdate = (user, field, value) => {
        dispatch(createUpdateUser(user.user_id, field, value, user[field]));
    };

    useEffect(() => {
        if (companyUsersData) {
            setCompanyUsers(companyUsersData);
            setEditingIndex(-1);
        }
        rebuildTooltip();
    }, [companyUsersData]);

    const onContinue = () => {
        for (const companyUser of companyUsers) {
            if ((companyUser.user_id && !companyUser.editing)
                || (!companyUser.user_id && !companyUser.to_be_added)
                || !companyUser.login_name
            ) { continue; }

            if (companyUser.to_be_added) {
                onCreate(companyUser);
            } else if (companyUser.user_id && companyUser.editing) {
                onUpdate(companyUser, 'login_name', companyUser.login_name);
            }
        }

        gotoNextStep();
    };

    return (
        <Row>
            <Col xs end={1}>
                <LabeledCheckbox
                    label="Show only active users"
                    checked={showOnlyActiveUsers}
                    value={showOnlyActiveUsers}
                    onChange={() => setShowOnlyActiveUsers(s => !s)}
                />
            </Col>
            <Col xs>
                <PreviewCompanyUsersTable
                    editingIndex={editingIndex}
                    setEditingIndex={setEditingIndex}
                    features={features}
                    companyUsers={companyUsers}
                    updateCompanyUserField={updateCompanyUserField}
                    companyType={companyType}
                    onCreate={onCreate}
                    onUpdate={onUpdate}
                />
            </Col>
            <Col xs center style={{paddingTop: 40, paddingBottom: 10}}>
                <Button variant='secondary' style={{ marginRight: 10 }} onClick={gotoPrevStep}>Back</Button>
                <Button variant='primary' onClick={onContinue}>Done</Button>
            </Col>
        </Row>
    );
});

const PreviewCompanyUsersTable = React.memo((props) => {
    const {
        features,
        companyUsers,
        updateCompanyUserField,
        editingIndex,
        companyType,
        onCreate,
        onUpdate,
    } = props;

    return (
        <>
            <Row style={{
                fontSize: 18,
                fontWeight: 'bold',
                paddingBottom: 10,
            }}>
                <Col xs sm={2.5} md={2.5}>First Name</Col>
                <Col xs sm={2.5} md={2.5}>Last Name</Col>
                <Col xs sm={3} md={3}>Login Email</Col>
                <Col xs sm={2.5} md={2.5}>Features</Col>
                <Col xs sm={1.5} md={1.5} style={{ textAlign: 'center' }}>Info</Col>
            </Row>
            {companyUsers.map((user, i) => (
                <PreviewCompanyUser
                    key={'company_user-' + i}
                    features={features}
                    user={user}
                    index={i}
                    updateField={(field, value) => updateCompanyUserField(i, field, value)}
                    editingIndex={editingIndex}
                    onCreate={onCreate}
                    onUpdate={onUpdate}
                />
            ))}
        </>
    );
});

const PreviewCompanyUser = React.memo((props) => {
    const {
        features,
        user,
        index,
        updateField,
        editingIndex,
        onCreate,
        onUpdate,
    } = props;

    const feature_options = useMemo(() => {
        return features.map(v => ({
            label: v.feature_name,
            value: v.feature_id,
        }));
    }, [features]);
    const isActiveText = parseInt(user.active) === 1 ? "Yes" : "No";
    const isHiddenText = parseInt(user.hidden) === 1 ? "Yes" : "No";
    const isFirstLoginText = parseInt(user.first_login) === 1 ? "Yes" : "No";
    const infoIconColor = parseInt(user.active) === 1 ? colors.secondary4.main : colors.secondary1.main;
    const activationLink = formatActivationLink(user.user_activation_key);

    return (
        <Row>
            <Col xs sm={2.5} md={2.5} pr={10}>
                {user.user_id
                    ? <Link as="a" href={`/user_details.php?id=${user.user_id}`} target='_blank'>
                        {(user.contact_first_name || '').trim()}
                    </Link>
                    : <Input
                        placeholder='First Name'
                        value={user.contact_first_name}
                        onChange={e => updateField('contact_first_name', e.target.value)}
                        disabled={editingIndex !== index && editingIndex !== -1}
                    />
                }
            </Col>
            <Col xs sm={2.5} md={2.5} pr={10}>
                {user.user_id
                    ? (user.contact_last_name || '').trim()
                    : <Input
                        placeholder='Last Name'
                        value={user.contact_last_name}
                        onChange={e => {
                            updateField('contact_last_name', e.target.value);
                        }}
                        disabled={editingIndex !== index && editingIndex !== -1}
                    />
                }
            </Col>
            <Col xs sm={3} md={3} pr={10}>
                <Input
                    placeholder='Login Email'
                    value={user.login_name}
                    onChange={e => {
                        updateField('login_name', e.target.value);
                    }}
                    disabled={editingIndex !== index && editingIndex !== -1}
                />
            </Col>
            <Col xs sm={2.5} md={2.5} pr={10}>
                <Select
                    inPopup
                    className="csku-select-v3"
                    options={feature_options}
                    value={user.feature_id}
                    onChange={e => {
                        updateField('feature_id', e.value, false);
                        if (!user.to_be_added) {
                            onUpdate(user, 'feature_id', e.value);
                        }
                    }}
                    isDisabled={(editingIndex !== index && editingIndex !== -1) || user.to_be_added}
                />
            </Col>
            <Col xs sm={1.5} md={1.5} style={{ textAlign: 'center' }}>
                {!user.editing && !user.to_be_added ? <span
                    data-html={true}
                    data-tip={`
                    <div>
                        <p>Contact Email: ${user.contact_email}</p>
                        <p>Role: ${user.role_name}</p>
                        <p>Active: ${isActiveText}</p>
                        <p>Hidden: ${isHiddenText}</p>
                        <p>Completed Signup: ${isFirstLoginText}</p>
                        <p style="width: 300px;">
                            Activiation Link: <a target="_blank" href=${activationLink}>${activationLink}</a>
                        </p>
                    </div>
                    `}
                    data-for={"user-info"}
                ><InfoIcon color={infoIconColor} /></span>
                : <>
                    <IconButton
                        Icon={CheckmarkIcon}
                        variant='primary-light'
                        size='tiny'
                        onClick={() => {
                            if (user.to_be_added) {
                                onCreate(user);
                            } else {
                                onUpdate(user, 'login_name', user.login_name);
                            }
                        }}
                        disabled={editingIndex !== index && editingIndex !== -1}
                    />
                </>}
            </Col>
        </Row>
    );
});

export default CompanyUsers;
