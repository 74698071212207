import _ from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { oauth } from '../utils';

const initialState = {};

export const getCompanyExtraData = (company) => {
    const data = {};
    const company_id = company.company_id;
    const company_name = company.company_name;
    const company_domain_key = company.company_domain_key;

    let company_type = company.company_type;
    if (!company_type) {
        if (company_id === undefined && company.tenant_id) {
            company_type = 'DISTRIBUTOR';
        } else if (company_id === undefined && company.supplier_id) {
            company_type = 'SUPPLIER';
        }
    }
    data['company_type'] = company_type;

    if (['DISTRIBUTOR', 'TENANT'].includes(company_type)) {
        if (company_name === undefined) {
            data['company_name'] = company.tenant_name;
        }
        if (company_id === undefined) {
            data['company_id'] = company.tenant_id;
        }
        if (company_domain_key === undefined) {
            data['company_domain_key'] = company.tenant_domain_key;
        }
    } else {
        if (company_name === undefined) {
            data['company_name'] = company.supplier_name;
        }
        if (company_id === undefined) {
            data['company_id'] = company.supplier_id;
        }
        if (company_domain_key === undefined) {
            data['company_domain_key'] = company.supplier_domain_key;
        }
    }

    return data;
};

const slice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setCompanies(state, action) {
        return action.payload;
    },
    fetchCompaniesSuccess(state, action) {
        return {
            ...state,
            ...action.payload.reduce((acc, v) => {
                const data = getCompanyExtraData(v);
                return {
                    ...acc,
                    [data.company_id]: {...data, ...v},
                };
            }, {}),
        };
    },
    updateCompanySuccess(state, action) {
        const data = getCompanyExtraData(action.payload);
        return {
            ...state,
            [action.payload.company_id]: {
                ...(state[action.payload.company_id] || {}),
                ...data,
                ...action.payload,
            },
        };
    },
  },
});

export const updateCompany = (company_type, company_id, params = {}) => async (dispatch, getState) => {
    try {
        const { json } = await oauth('PUT', `${company_type}/${company_id}`, params);

        let companyType = company_type;
        if (company_type === 'tenant') {
            companyType = 'DISTRIBUTOR';
        }
        const data = _.get(json, company_type, {});
        dispatch(actions.updateCompanySuccess({
            ...data,
            company_id,
            company_type: companyType.toUpperCase(),
        }));
        return data;
    } catch (error) {
        console.error('ERROR|updateCompanySuccess| ', error);
        let err = error.json.error;
        if (Array.isArray(err) && err.length > 0) {
            err = err[0];
        }
        return { error: err || 'Unable to update company' };
    }
};

const getCompanies = s => _.get(s, 'companies', {});

export const selectors = {
    companies: createSelector(getCompanies, s => s),
    byId: createSelector([getCompanies, (s, id) => id], (s, id) => s[id]),
};

export const actions = slice.actions;
export const reducer = slice.reducer;

export default slice;
